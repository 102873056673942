*, :before, :after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: #252525 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 32px !important;
}

.widget-application--smallMobile *, .widget-application--smallMobile :before, .widget-application--smallMobile :after {
  font-size: 16px !important;
}

html {
  --preloader-size: 24px;
}

@media (width <= 1024px) {
  html {
    --preloader-size: 18px;
  }
}

@media (width <= 320px) {
  html {
    --preloader-size: 12px;
  }
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.widget-section, .widget-section .widget {
  width: 100%;
  height: 100%;
}

.widget-section .widget-ru .widget-instructions__hint p {
  color: #413e3e !important;
  font-size: 14px !important;
}

@media (width <= 670px) {
  .widget-section .widget-ru .widget-instructions__hint p {
    font-size: 10px !important;
    line-height: 125% !important;
  }
}

@media screen and (width >= 2200px) {
  .widget-section .widget-ru .widget-instructions__hint p {
    font-size: 1rem !important;
    line-height: 1.4rem !important;
  }
}

@media (width <= 670px) {
  .widget-section .widget-ru .widget-instructions__step p {
    font-size: 20px !important;
  }
}

@media (width <= 320px) {
  .widget-section .widget-ru .widget-instructions__step p {
    font-size: 18px !important;
  }
}

@media screen and (width >= 2200px) {
  .widget-section .widget-ru .widget-instructions__step p {
    font-size: 1rem !important;
    line-height: 1.4rem !important;
  }
}

.widget-instructions__content {
  background-color: #dfdfdf !important;
}

@media screen and (width <= 300px) {
  .widget-instructions__content {
    max-width: calc(100% - 15px) !important;
  }
}

@media screen and (width >= 2200px) {
  .widget-instructions__content {
    border-radius: 1rem !important;
    width: 20rem !important;
    padding: 1.8rem 1.5rem 1.5rem !important;
  }
}

@media (width <= 670px) {
  .widget-instructions__content button {
    height: 35px !important;
    padding: 0 33px !important;
  }
}

@media screen and (width >= 2200px) {
  .widget-instructions__content button {
    height: 2.2rem !important;
    margin: 1.25rem auto 0 !important;
    padding: 0 1.65rem !important;
  }
}

.widget-watermark-button {
  display: none;
}

.widget-tab__panorama-controls {
  display: none !important;
}

.widget-tab__panorama-controls-button--ruler, .widget-tab__panorama-controls-button--scale {
  background: #ebebebd9 !important;
  width: 52px !important;
  height: 52px !important;
  margin-right: 0 !important;
}

.widget-application--mobile .widget-tab__panorama-controls-button--ruler, .widget-application--mobile .widget-tab__panorama-controls-button--scale {
  width: 36px !important;
  height: 36px !important;
}

@media screen and (width >= 2200px) {
  .widget-tab__panorama-controls-button--ruler, .widget-tab__panorama-controls-button--scale {
    width: 2.17rem !important;
    height: 2.17rem !important;
  }
}

.widget-tab__panorama-controls-button--scale img {
  display: none !important;
}

.widget-tab__panorama-controls-button--scale span {
  pointer-events: none !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
}

.widget-application--mobile .widget-tab__panorama-controls-button--scale span {
  font-size: 13px !important;
  line-height: 20px !important;
}

.widget-tab__panorama-controls-button--ruler img {
  width: 24px !important;
  height: 24px !important;
}

@media (width <= 1100px) {
  .widget-tab__panorama-controls-button--ruler img {
    width: 34px !important;
    height: 34px !important;
  }
}

@media screen and (width >= 2200px) {
  .widget-tab__panorama-controls-button--ruler img {
    width: 1.2rem !important;
    height: 1.2rem !important;
  }
}

.widget-tab__panorama-map {
  right: 45px;
  left: unset !important;
}

.widget-tab__panorama-map:before {
  left: unset !important;
  right: 50% !important;
  transform: translate(50%, -50%) !important;
}

.widget-application--tablet .widget-tab__panorama-map {
  transform-origin: 100% 100% !important;
  right: 35px !important;
}

.widget-application--mobile .widget-tab__panorama-map {
  right: 22px !important;
}

.widget-application--smallMobile .widget-tab__panorama-map {
  right: 15px !important;
}

@media screen and (width >= 2200px) {
  .widget-tab__panorama-map {
    bottom: 1.75rem !important;
    right: 2.25rem !important;
    transform: scale(1.3)translate(-20%, -20%) !important;
  }
}

@media screen and (width >= 2560px) {
  .widget-tab__panorama-map {
    transform: scale(1.6)translate(-25%, -25%) !important;
  }
}

@media screen and (width >= 2960px) {
  .widget-tab__panorama-map {
    transform: scale(2)translate(-25%, -30%) !important;
  }
}

@media screen and (width >= 5000px) {
  .widget-tab__panorama-map {
    transform: scale(2.5)translate(-30%, -35%) !important;
  }
}

.widget-application--tablet .widget-tab__panorama-map--scaled {
  left: unset !important;
  right: 50% !important;
  transform: scale(1.4)translate(50%, 50%) !important;
}

.widget-application--tablet .widget-tab__panorama-map--scaled:after {
  left: unset !important;
  right: 50% !important;
  transform: translate(50%, -50%) !important;
}

.widget-application--mobile .widget-tab__panorama-map--scaled {
  transform: scale(1)translate(50%, 50%) !important;
}

.widget-instructions__content button {
  text-transform: uppercase !important;
  font-size: 24px !important;
}

@media screen and (width >= 2200px) {
  .widget-instructions__content button {
    font-size: 1.2rem !important;
  }

  .widget-instructions__step {
    margin: 0 .75rem !important;
  }
}

.widget-instructions__step p {
  max-width: 155px !important;
  font-size: 24px !important;
  line-height: 30px !important;
}

@media screen and (width >= 2200px) {
  .widget-instructions__step p {
    max-width: 7.75rem !important;
    font-size: 1.2rem !important;
    line-height: 1.25rem !important;
  }

  .widget-instructions__step img {
    width: 2.6rem !important;
    height: 4rem !important;
  }
}

.widget-instructions__hint p {
  font-size: 16px !important;
  line-height: 20px !important;
}

@media screen and (width >= 2200px) {
  .widget-instructions__hint p {
    font-size: .66rem !important;
    line-height: .83rem !important;
  }
}

.widget-tab-buttons {
  border-radius: 60px;
  transform: translateX(-50%);
  left: 50% !important;
  right: unset !important;
  background: #ebebeb4d !important;
  justify-content: flex-start !important;
  column-gap: 8px !important;
  padding: 8px !important;
}

.widget-application--mobile .widget-tab-buttons {
  padding: 6px !important;
  left: 12px !important;
  transform: none !important;
}

@media (width <= 670px) {
  .widget-tab-buttons {
    bottom: 25px !important;
  }
}

@media screen and (width >= 2200px) {
  .widget-tab-buttons {
    column-gap: .4rem !important;
    padding: .4rem !important;
    bottom: 2.15rem !important;
  }
}

.widget-tab-button {
  background-color: #ebebebd9 !important;
  width: 52px !important;
  height: 52px !important;
  margin-left: 0 !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
}

.widget-application--mobile .widget-tab-button {
  width: 36px !important;
  height: 36px !important;
  font-size: 13px !important;
  line-height: 20px !important;
}

@media screen and (width >= 2200px) {
  .widget-tab-button {
    width: 2.17rem !important;
    height: 2.17rem !important;
    font-size: 1rem !important;
    line-height: 1.4rem !important;
  }
}

.widget-tab-button--active {
  background-color: #ffd180 !important;
}

@media screen and (width >= 2200px) {
  .widget-tab__isometry-container img {
    width: 30rem !important;
    max-width: 30rem !important;
    height: 30rem !important;
    max-height: 30rem !important;
  }
}

.widget-tab__isometry-buttons {
  bottom: 43px !important;
  right: 45px !important;
  left: unset !important;
}

.widget-application--mobile .widget-tab__isometry-buttons {
  bottom: 31px !important;
  right: 15px !important;
}

@media screen and (width >= 2200px) {
  .widget-tab__isometry-buttons {
    bottom: 2.55rem !important;
    right: 2.25rem !important;
  }
}

.widget-tab__isometry-button {
  left: unset !important;
  background-color: #ebebebd9 !important;
  width: 52px !important;
  height: 52px !important;
  bottom: 29px !important;
}

@media (width <= 670px) {
  .widget-tab__isometry-button {
    width: 36px !important;
    height: 36px !important;
    bottom: 29px !important;
  }
}

@media (width <= 375px) {
  .widget-tab__isometry-button {
    width: 36px !important;
    height: 36px !important;
    bottom: 29px !important;
  }
}

@media screen and (width >= 2200px) {
  .widget-tab__isometry-button {
    width: 2.17rem !important;
    height: 2.17rem !important;
  }
}

.widget-tab__isometry-button img {
  width: 36px !important;
  height: 36px !important;
}

@media (width <= 670px) {
  .widget-tab__isometry-button img {
    width: 25px !important;
    height: 25px !important;
  }
}

@media screen and (width >= 2200px) {
  .widget-tab__isometry-button img {
    width: 1.7rem !important;
    height: 1.7rem !important;
  }
}

.widget-tab__isometry-arrows {
  left: unset !important;
  bottom: unset !important;
  background-color: #ebebebd9 !important;
  border-radius: 60px !important;
  justify-content: space-between !important;
  width: 107px !important;
  height: 52px !important;
  padding: 7px !important;
  position: relative !important;
  transform: none !important;
}

.widget-application--mobile .widget-tab__isometry-arrows {
  width: 74px !important;
  height: 36px !important;
}

@media screen and (width >= 2200px) {
  .widget-tab__isometry-arrows {
    border-radius: 1.5rem !important;
    width: 5.35rem !important;
    height: 2.17rem !important;
    padding: .35rem !important;
  }
}

.widget-tab__isometry-arrows:before {
  display: none !important;
}

.widget-tab__isometry-arrows span {
  margin: 0 !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
}

.widget-application--mobile .widget-tab__isometry-arrows span {
  font-size: 13px !important;
  line-height: 20px !important;
}

@media screen and (width >= 2200px) {
  .widget-tab__isometry-arrows span {
    font-size: 1rem !important;
    line-height: 1.4rem !important;
  }
}

.widget-tab__isometry-arrows img {
  width: 20px !important;
  height: 20px !important;
}

.widget-application--mobile .widget-tab__isometry-arrows img {
  width: 15px !important;
  height: 15px !important;
}

@media screen and (width >= 2200px) {
  .widget-tab__isometry-arrows img {
    width: 1rem !important;
    height: 1rem !important;
  }
}

.widget-tab__isometry-arrow {
  padding: 0 !important;
}

.widget-tab__rotation .widget-tab__isometry-arrows, .widget-application > .floors-select {
  display: none;
}

.widget-preloader-frame {
  z-index: 1000;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity 2s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.widget-preloader-frame.hidden, .widget-preloader-frame.hidden .widget-preloader {
  pointer-events: none;
  opacity: 0;
}

.widget-preloader-frame .widget-preloader {
  font-size: var(--preloader-size);
  width: 5em;
  height: 5em;
  position: relative;
}

.widget-preloader-frame .widget-preloader div {
  background-color: #ffd180;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  position: absolute;
  top: 2em;
}

.widget-preloader-frame .widget-preloader div:first-child {
  animation: .6s infinite widget-preloader-1;
  left: 0;
}

.widget-preloader-frame .widget-preloader div:nth-child(2) {
  animation: .6s infinite widget-preloader-2;
  left: 0;
}

.widget-preloader-frame .widget-preloader div:nth-child(3) {
  animation: .6s infinite widget-preloader-2;
  left: 2em;
}

.widget-preloader-frame .widget-preloader div:nth-child(4) {
  animation: .6s infinite widget-preloader-3;
  left: 4em;
}

@keyframes widget-preloader-1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes widget-preloader-2 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(2em);
  }
}

@keyframes widget-preloader-3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

.widget-loader div:first-child, .widget-loader div:nth-child(2), .widget-loader div:nth-child(3) {
  background-color: #ffd180 !important;
}

.floors-select {
  top: unset !important;
  left: unset !important;
  background: #ebebebd9 !important;
  border-bottom: none !important;
  border-radius: 30px !important;
  height: 52px !important;
  padding: 12px 23px !important;
  position: relative !important;
  transform: none !important;
}

@media (width <= 670px) {
  .floors-select {
    width: 72px !important;
    height: 36px !important;
    padding: 8px 16px !important;
  }
}

@media screen and (width >= 2200px) {
  .floors-select {
    border-radius: 1.5rem !important;
    height: 2.17rem !important;
    padding: .4rem 1.15rem !important;
  }
}

.floors-select--text {
  word-spacing: 0 !important;
  border-bottom: 0 !important;
  padding: 0 !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
}

@media (width <= 670px) {
  .floors-select--text {
    font-size: 13px !important;
    line-height: 20px !important;
  }
}

@media screen and (width >= 2200px) {
  .floors-select--text {
    font-size: 1rem !important;
    line-height: 1.4rem !important;
  }
}

.floors-select--block {
  background: #ebebebd9 !important;
  border-radius: 15px !important;
  flex-direction: column-reverse !important;
  position: absolute !important;
  bottom: 60px !important;
  right: 0 !important;
}

@media screen and (width >= 2200px) {
  .floors-select--block {
    border-radius: .75rem !important;
    bottom: 3rem !important;
  }
}

.floors-select--block-item {
  word-spacing: 0 !important;
  padding: 12px 22px !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
}

@media (width <= 670px) {
  .floors-select--block-item {
    padding: 8px 16px !important;
    font-size: 13px !important;
    line-height: 20px !important;
  }
}

@media screen and (width >= 2200px) {
  .floors-select--block-item {
    padding: .6rem 1.1rem !important;
    font-size: 1rem !important;
    line-height: 1.4rem !important;
  }
}

@media (width <= 1025px) {
  .widget-enter-fullscreen {
    top: 63px !important;
    right: 0 !important;
  }
}

@media (width <= 670px) {
  .widget-enter-fullscreen {
    padding: 10px !important;
    top: 68px !important;
  }
}

@media screen and (width >= 2200px) {
  .widget-enter-fullscreen {
    padding: .5rem !important;
    top: 1.8rem !important;
    right: 2.25rem !important;
  }
}

@media (width <= 670px) {
  .widget-enter-fullscreen img {
    width: 40px !important;
    height: 40px !important;
  }
}

@media screen and (width >= 2200px) {
  .widget-enter-fullscreen img {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
}

@media (width <= 1025px) {
  .widget-exit-fullscreen {
    top: 63px !important;
    right: 0 !important;
  }
}

@media (width <= 670px) {
  .widget-exit-fullscreen {
    padding: 10px !important;
    top: 68px !important;
  }
}

@media screen and (width >= 2200px) {
  .widget-exit-fullscreen {
    padding: .5rem !important;
    top: 1.8rem !important;
    right: 2.25rem !important;
  }
}

@media (width <= 670px) {
  .widget-exit-fullscreen img {
    width: 40px !important;
    height: 40px !important;
  }
}

@media screen and (width >= 2200px) {
  .widget-exit-fullscreen img {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }

  html {
    font-size: 20px !important;
  }
}

@media screen and (width >= 2560px) {
  html {
    font-size: 24px !important;
  }
}

@media screen and (width >= 2960px) {
  html {
    font-size: 32px !important;
  }
}

@media screen and (width >= 5000px) {
  html {
    font-size: 40px !important;
  }
}

.w-back {
  z-index: 10;
  background: #fff;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}
/*# sourceMappingURL=index.72efcc5b.css.map */
